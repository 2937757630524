import React from 'react'
import about_us from './images/aboutus.png'
import background from './images/bgmain.png'
import './About.css'

const About = () => {
    return (
        <div className='about mt-2' id='about'>
            <div className='container' style={{ backgroundImage: `url(${background})` }}>
                <div>.</div>
                <div className="row mt-4">
                    <div className="col">
                        <img src={about_us} alt='john' width={500} height={450}/>
                    </div>
                    <div className="col">
                        <h3>Business Background</h3>
                        <span className='line'></span>
                        <p></p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy 
                            text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, 
                            but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy 
                            text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, 
                            but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        {/* <button className='button'>Explore More</button> */}
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className="col">
                        <h3>Our Vision</h3>
                        <span className='line'></span>
                        <p>To become the prefered choice for professional real property consultancy firm in East African Market.</p>
                    </div>
                    <div className="col">
                        <h3>Our Values</h3>
                        <span className='line'></span>
                        <p>* Integrity and Confidence</p>
                        <p>* Involvement and Focus</p>
                    </div>
                    <div className="col">
                        <h3>Our Mission</h3>
                        <span className='line'></span>
                        <p>To deliver professionally acclaimed and consistent report and genuine real property four our client</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
