import React from 'react'
import './Hero.css'

const Hero = () => {
    return (
        <div className='hero'>
            <div className='content'>
                <p>.</p>
                <p className=''>Welcome To</p>
                <p>Binary Real Property</p>
                <p style={{fontSize: '22px'}}>We strive to offer the best real property services to you...</p>
                <button href='/' className='button'>Explore</button>
            </div>
        </div>
    )
}

export default Hero
