import React from 'react'
import bgImg from './images/bgmain.png'
import './Demo.css'

const Demo = () => {
    return (
        <div className='why_us' id='why_us'>
            <div className="container" style={{backgroundImage:` url(${bgImg})`}}>
                    <div className="row">
                        <div className="col-md-12 md-4 text-center">
                            <h4 className="main-heading t-6 mt-4">Why you should choose us</h4>
                            <div className="underlined mx-auto"></div>
                        </div>
                        <div className="row text-center stat-wrap mt-5">
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="rounded-circle shadow-4-strong bg-secondary mx-auto" style={{width: '80px', height: '80px'}}>
                                    <div className=''>
                                        <span data-scroll class="global-radius icon_wrap effect-2"><i className="">.</i></span>
                                        <h4 style={{color: '#fff'}}>124</h4>
                                    </div>
                                </div>
                                <h4 className="text-primary m-2">Completed Projects</h4>
                            </div>
                            <div class="col-md-3 col-sm-6 col-xs-12">
                                <div className="rounded-circle shadow-4-strong bg-secondary mx-auto" style={{width: '80px', height: '80px'}}>
                                    <div className=''>
                                        <span data-scroll class="global-radius icon_wrap effect-2"><i className="">.</i></span>
                                        <h4 style={{color: '#fff'}}>83</h4>
                                    </div>
                                </div>
                                <h4 className="text-primary m-2">Happy Clients</h4>
                            </div>
                            <div class="col-md-3 col-sm-6 col-xs-12">
                                <div className="rounded-circle shadow-4-strong bg-secondary mx-auto" style={{width: '80px', height: '80px'}}>
                                    <div className=''>
                                        <span data-scroll class="global-radius icon_wrap effect-2"><i className="">.</i></span>
                                        <h4 style={{color: '#fff'}}>09</h4>
                                    </div>
                                </div>
                                <h4 className="text-primary m-2">Years of experiense</h4>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="rounded-circle shadow-4-strong bg-secondary mx-auto" style={{width: '80px', height: '80px'}}>
                                    <div className=''>
                                        <span data-scroll class="global-radius icon_wrap effect-2"><i className="">.</i></span>
                                        <h4 style={{color: '#fff'}}>920</h4>
                                    </div>
                                </div>
                                <h4 className="text-primary m-2">Answered Questions</h4>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Demo
