import catImg from './images/3.jpg'

export const PropertyList = [
    {
        "image": catImg,
        "name": "Property Name",
        "price": "Ugx 250,000,000",
        "location": "Nakwero Gayaza",
        "description": "..................",
        "lat": "0.988766",
        "lon": "32.345679" 
    },
    {
        "image": catImg,
        "name": "Property Name",
        "price": "Ugx 350,000,000",
        "location": "Nakwero Gayaza",
        "description": "..................",
        "lat": "0.988766",
        "lon": "32.345679" 
    },
    {
        "image": catImg,
        "name": "Property Name",
        "price": "Ugx 150,000,000",
        "location": "Nakwero Gayaza",
        "description": "..................",
        "lat": "0.988766",
        "lon": "32.345679" 
    },
    {
        "image": catImg,
        "name": "Property Name",
        "price": "Ugx 250,000,000",
        "location": "Nakwero Gayaza",
        "description": "..................",
        "lat": "0.988766",
        "lon": "32.345679" 
    },
    {
        "image": catImg,
        "name": "Property Name",
        "price": "Ugx 250,000,000",
        "location": "Nakwero Gayaza",
        "description": "..................",
        "lat": "0.988766",
        "lon": "32.345679" 
    }
]

export const CategoryList = [
    {
        "image": catImg,
        "name": "Bungalow"
    },
    {
        "image": catImg,
        "name": "Apartments"
    },
    {
        "image": catImg,
        "name": "Mansions"
    },
    {
        "image": catImg,
        "name": "Land"
    }
];

export const layout = [
    { i: "a", x: 0, y: 0, w: 1, h: 2,},
    { i: "b", x: 1, y: 0, w: 3, h: 2, },
    { i: "c", x: 4, y: 0, w: 1, h: 2 }
];