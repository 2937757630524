import React, { useState, useEffect } from 'react'
import { CategoryList, PropertyList } from './DynamicData'
import './Testimonials.css'
import background from './images/bgmain.png'

const Testimonials = () => {
    const [categories, setCategories] = useState([]);
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        setCategories(CategoryList);
        setProperties(PropertyList);
    },[]);
    return (
        <div className='properties mt-2' id='properties'>
            <div className='container' style={{ backgroundImage: `url(${background})` }}>
                {/* <div className=''>.</div> */}
                <h3 className="m-2">Properties On Sale</h3>
                <div className="row m-4">
                    <div className="" style={{ width: '240px'}}>
                        <div>
                            <div className="input-group rounded mt-4">
                                <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                            </div>
                        </div>
                        <div className="mt-4">
                            <h6>Categories</h6>
                            {categories.map((cat, index) => {
                                return (
                                    <div className="row mt-4" key={index} style={{height: '60px', width: '250px'}}>
                                        <div className="col">
                                            <img src={cat.image} class="rounded-circle mx-auto d-block" width={50} height={50} alt="..."/>
                                        </div>
                                        <div className="col ">
                                            {cat.name}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col">
                        <div className="grid">
                        {properties.map((pro, index) => {
                            return (
                                <div className="item" style={{width: '18rem'}} key={index}>
                                    <div className='card'>
                                        <div className='m-2'>
                                            <img className="card-img-top mt-1" src={pro.image} width={100} alt=""/>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="card-title">{pro.name}</h6>
                                            <p className="card-text">{pro.price}</p>
                                            <p className="card-text"><i class="bi bi-geo-alt-fill" aria-hidden="true"></i> {pro.location}</p>
                                        </div>
                                        <div className='d-flex justify-content-center m-2'>
                                            <button type="button" class="btn btn-pill btn-outline-success">View Details</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        </div>  
                    </div>
                    <div className='mt-4'></div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials
