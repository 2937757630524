import React from 'react'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import Properties from './components/Properties'
import About from './components/About'
import WhyUs from './components/WhyUs'
import Testimonials from './components/Testimonials'
import Demo from './components/Demo'
import Footer from './components/Footer'

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      {/* <Properties/> */}
      <About />
      <Testimonials />
      {/* <WhyUs/> */}
      <Demo />
      <Footer />
    </div>
  );
}

export default App;
