import React from 'react'
import './Footer.css'

const Footer = () => {
    var date = new Date();
    let today = date.getFullYear();

    return (
        <div className='footer'>
            <div className='container'>
                <div className='row'>
                    <div className="col-md-4 mt-4">
                        <h5 className="centeredtext">Our Services</h5>
                        <hr/>
                        <p>Agency</p>
                        <p>Property Managment</p>
                        <p>Valution</p>
                        <p>Land Surveying</p>
                        {/* <p>Agency</p> */}
                    </div>
                    <div className="col-md-4 mt-4">
                        <h5 className="centeredtext">Quick Navigation</h5>
                        <hr/>
                        <p><a href="/" style={{textDecoration: 'none'}}><i class="" aria-hidden="true"></i>Home</a></p>
                        <p><a href="/#about" style={{textDecoration: 'none'}}><i class="" aria-hidden="true"></i>About</a></p>
                        <p><a href="/#properties" style={{textDecoration: 'none'}}><i class="" aria-hidden="true"></i>Properties</a></p>
                        <p><a href="/#why_us" style={{textDecoration: 'none'}}><i class="" aria-hidden="true"></i>Why Us</a></p>
                    </div>
                    <div className="col-md-4 mt-4">
                        <h5 className="centeredtext">Anytime you need us</h5>
                        <hr/>
                        <p><i class="bi bi-geo-alt-fill" aria-hidden="true"></i> Kampala, Uganda, The Plaz</p>
                        <p><i class="bi bi-envelope-check-fill" aria-hidden="true"></i> npowell@gmail.com</p>
                        <p><i class="bi bi-telephone-fill" aria-hidden="true"></i> +256 783 727276 / +256 753 727277</p>
                    </div>
                </div>
                {/* <ul>
                    <li className='nav-item'>
                        <a href='/'>Home</a>
                    </li>
                    <li className='nav-item'>
                        <a href='#about'>About</a>
                    </li>
                    <li className='nav-item'>
                        <a href='#testimonials'>Testimonials</a>
                    </li>
                    <li className='nav-item'>
                        <a href='#demo'>Demo</a>
                    </li>
                </ul> */}
                <div className='bottom'>
                    <span className='line'></span>
                    <p>copyrights &copy; {today} All rights reserved | Binary Real Property </p>
                </div>
            </div>
        </div>
    )
}

export default Footer
